"use client";

import React, {
  MouseEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { Box, Collapse, Divider } from "@mui/material";
import clsx from "clsx";

import { sendGaUserInteractionEvent } from "common/util/googleAnalytics";
import { useIsMobile } from "common/util/hooks/useIsMobile";
import { useGetSideNavItemStyles } from "layout/SideNav/SideNavMenu/util/useGetSideNavItemStyles";

import { SideNavArrowButton } from "layout/SideNav/SideNavMenu/MenuItems/SideNavArrowButton";
import { SideNavText } from "layout/SideNav/SideNavMenu/MenuItems/SideNavText";
import { useScrollSpyStore } from "common/state/useScrollSpyStore";
import { getHtmlIdForItem, NavItem } from "../SideNavMenu";
import { sortItems } from "./sortItems";
import { useItemSelectionStatus } from "./useItemSelectionStatus";

export type SideNavMenuMenuItemBaseProps = {
  item: NavItem;
  selected?: string | undefined | null;
  parentSelected: boolean;
};

export const SideNavMenuMenuItemBase: React.FC<SideNavMenuMenuItemBaseProps> = ({
  item: _item,
  selected,
  parentSelected
}) => {
  const items = useMemo(() => sortItems(_item.id, _item.items ?? []), [_item.id, _item.items]);
  const item = { ..._item, items };
  const active = useScrollSpyStore((store) => store.active);
  const { isSelected, isActive, hasItems, anyChildSelected } = useItemSelectionStatus({
    item,
    selected: item.level >= 3 && parentSelected ? active ?? selected : selected,
    parentSelected,
    active
  });

  const isMobile = useIsMobile();
  const itemRef = useRef<HTMLDivElement>();
  const [open, setOpen] = useState<boolean>(
    isActive || (isSelected && hasItems) || anyChildSelected || false
  );
  const { showLeftBorder, leftBorderStyle, itemIndent, activeBackgroundColor } =
    useGetSideNavItemStyles({
      level: item.level,
      items: item.items,
      category: item.category,
      isActive,
      isSelected,
      anyChildSelected,
      parentSelected
    });

  useEffect(() => {
    if (open) return;
    if (anyChildSelected || (isSelected && hasItems)) {
      setOpen(true);
    }
  }, [open, hasItems, isSelected, anyChildSelected]);

  const handleToggle = useCallback(
    (evt: SyntheticEvent, value?: boolean, itemTitle?: string) => {
      if (itemTitle) {
        sendGaUserInteractionEvent({
          category: "Side Nav",
          action: open ? "Toggle Closed" : "Toggle Open",
          label: itemTitle
        });
      }
      setOpen(value === undefined ? !open : value);
    },
    [open, setOpen]
  );

  useEffect(() => {
    if (isMobile && isSelected) {
      itemRef.current?.scrollIntoView();
    }
  }, [isSelected, isMobile]);

  return (
    <>
      {item.showCategory === false && <Divider sx={{ my: "20px" }} />}
      {item.showCategory !== false && (
        <Box
          ref={itemRef}
          id={getHtmlIdForItem(item)}
          onClick={
            hasItems
              ? (event: MouseEvent, value?: boolean) =>
                  handleToggle(event, value, typeof item.title === "string" ? item.title : "")
              : undefined
          }
          className={clsx({ selected: isSelected && !anyChildSelected, active: isActive })}
          sx={{
            display: "flex",
            alignItems: "center",
            py: 0.75,
            pr: 0.75,
            gap: 1,
            pl: showLeftBorder ? (item.level === 3 ? "12px" : "22px") : itemIndent,
            ml: showLeftBorder ? itemIndent : undefined,
            minHeight: "42px",
            transition: "background-color 0.2s",
            overflow: "hidden",
            backgroundClip: "padding-box",
            "&:hover": {
              backgroundColor: "component.background"
            },
            "&.selected": {
              backgroundColor: activeBackgroundColor
            },
            "&:hover.selected": {
              backgroundColor: activeBackgroundColor
            },
            ...(showLeftBorder ? leftBorderStyle : {})
          }}
        >
          <SideNavText
            item={item}
            selected={isSelected}
            active={isActive}
            anyChildSelected={anyChildSelected}
          />
          {hasItems && <SideNavArrowButton title={item.title} open={open} />}
        </Box>
      )}
      {hasItems && (
        <Collapse
          in={item.showCategory === false ? true : open}
          sx={{ ml: showLeftBorder ? itemIndent : undefined }}
        >
          {items?.sort().map((childItem) => (
            <SideNavMenuMenuItemBase
              key={`item-base-${childItem.id}`}
              item={childItem}
              selected={selected}
              parentSelected={isSelected || (item.level >= 3 ? isActive : false)}
            />
          ))}
        </Collapse>
      )}
    </>
  );
};

"use client";

import { useEffect } from "react";
import { usePathname } from "next/navigation";

import { logInfo } from "common/util/consoleHelpers";

import { useLayoutStore } from "common/state/useLayoutStore";
import { useNavigationStore } from "common/state/useNavigationStore";

export const useDetectUrlUpdate = () => {
  const { setIsNavigating, isNavigating } = useNavigationStore((store) => store);
  const { setMobileNav } = useLayoutStore((s) => s);
  const pathname = usePathname();

  useEffect(() => {
    logInfo("URL Path Monitoring UE");
    if (isNavigating === false) return;
    return () => {
      if (isNavigating === true) {
        setIsNavigating(false);
        setMobileNav(false);
      }
    };
  }, [isNavigating, pathname, setIsNavigating, setMobileNav]);
};

export const UrlPathMonitoring = () => {
  useDetectUrlUpdate();
  return <></>;
};

import compact from "lodash/compact";
import {
  dashboardSlug as dodSlug,
  pagesMap as dodPagesMap
} from "app/topics/drug-overdose-deaths/[subTopicId]/[locationId]/config";

import { NavItem } from "../SideNavMenu";

export const addOverviewTopic: string[] = ["covid-19", "asthma", dodSlug];

const exceptions: Record<string, string[]> = {
  ecdc: ["ecdcOverview", "ecdcHealth", "ecdcEducation", "ecdcJustice", "ecdcWealth"],
  lead: [
    "leadOverview",
    "test-results",
    "risk-factors",
    "housing",
    "testing-coverage",
    "additional-information"
  ],
  flu: ["overview", "positive-cases", "hospitalizations", "vaccinations"],
  portals: ["cpr", "ecdc", "stories"],
  "covid-19": [
    "covid-19/cases",
    "covid-19/vaccines",
    "covid-19/hospitalizations",
    "covid-19/deaths"
  ],
  cpr: [
    "cpr-overview",
    "populationAndDemographics",
    "svi",
    "infectiousDisease",
    "chronicDisease",
    "mentalHealth",
    "climateChange",
    "maternalAndChild",
    "healthyLifestyles"
  ],
  asthma: ["asthma/er-visits", "asthma/hospitalizations", "asthma/deaths"],
  [dodSlug]: compact(
    Object.values(dodPagesMap).map(({ id, topicSlug }) =>
      id !== "overview" ? topicSlug : undefined
    )
  )
};

export const addOverviewItems = (items: NavItem[]): NavItem[] => {
  return items.map((item) => {
    const baseItem = { ...item };
    const exception = exceptions[item.id];
    let _items = exception
      ? (item.items ?? []).sort((a, b) => {
          const aIndex = exception.indexOf(`${a?.id}`);
          const bIndex = exception.indexOf(`${b?.id}`);
          return aIndex - bIndex;
        })
      : item.items ?? [];
    if (addOverviewTopic.includes(item.id)) {
      const sItem = _items[0];
      baseItem.href = (sItem?.href ?? "").replace(sItem?.id ?? "", `${item.id}/overview`);
      const overviewItem = {
        ...sItem,
        id: `${item.id}/overview`,
        title: "Overview",
        items: [],
        icon: sItem?.icon ?? undefined,
        href: (sItem?.href ?? "").replace(sItem?.id ?? "", `${item.id}/overview`),
        type: sItem?.type ?? "topic",
        level: sItem?.level ?? 3,
        category: sItem?.category ?? "all_topics"
      };
      _items = [overviewItem, ..._items];
    }
    return {
      ...baseItem,
      items: addOverviewItems(_items)
    };
  });
};

export const sortItems = (id: string, items: NavItem[]): NavItem[] => {
  const exception = exceptions[id];
  if (exception) {
    const baseItems = items?.sort((a, b) => {
      const aIndex = exception.indexOf(`${a?.id}`);
      const bIndex = exception.indexOf(`${b?.id}`);
      return aIndex - bIndex;
    });
    return baseItems;
  }
  return items?.sort((a, b) =>
    typeof a?.title === "string" && typeof b?.title === "string"
      ? a?.title.localeCompare(b.title)
      : a.id.localeCompare(b.id)
  );
};

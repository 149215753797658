import { NavItem } from "./SideNavMenu";

export const getIsSelected = (
  item: NavItem,
  parentSelected: boolean,
  selected?: string | null | undefined,
  url?: string | undefined,
  active?: string | null | undefined
): boolean => {
  if (!selected) return false;
  if (item.id === selected) return true;
  if (item.level > 3 && active === item.id) return true;
  if (item.level > 3 && parentSelected === false) return false;
  if (getIsChildSelected(item, parentSelected, selected, url)) return false; // eslint-disable-line @typescript-eslint/no-use-before-define
  if (url && item.href && url.match(new RegExp(item.href))) return true;
  if (item.title === selected) return true;
  return false;
};

export const getIsChildSelected = (
  item: NavItem,
  parentSelected: boolean,
  selected?: string | null | undefined,
  url?: string | undefined,
  active?: string | null | undefined
): boolean => {
  return (item.items || []).some(
    (child) =>
      getIsSelected(child, parentSelected, selected, url, active) ||
      getIsChildSelected(child, parentSelected, selected, url)
  );
};

export const getIsDirectChildSelected = (
  item: NavItem,
  parentSelected: boolean,
  selected?: string | null | undefined,
  url?: string | undefined
): boolean => {
  return (item.items || []).some((child) => getIsSelected(child, parentSelected, selected, url));
};

export const getIsActive = (
  item: NavItem,
  parentSelected: boolean,
  selected?: string | null | undefined,
  url?: string | undefined
): boolean => {
  if (!selected) return false;
  if (getIsSelected(item, parentSelected, selected, url)) return false; // Mutually exclusive states
  if (getIsChildSelected(item, parentSelected, selected, url)) return true;

  return false;
};

"use client";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useRouter } from "next/navigation";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box, Breadcrumbs, Stack, Typography } from "@mui/material";
import isNil from "lodash/isNil";
import { getMhcMapDataWithStats } from "graphqlApi/legacy/mhcClient";

import {
  MhcFeatureCollection,
  MhcGeographyEnum,
  MhcLocation,
  MhcLocationFragment,
  MhcTopicFragment
} from "graphqlApi/types";

import { allLocationsBasicData } from "common/components/LocationSwitcher/util/allLocationsBasicData";
import {
  groupAndTurnLocationsIntoFeatures,
  NoCountry
} from "common/components/LocationSwitcher/util/groupAndTurnLocationsIntoFeatures";
import { mostPopularLocations } from "common/components/LocationSwitcher/util/mostPopularLocationts";
import { updateLocationOnCurrentPath } from "common/components/LocationSwitcher/util/updateLocationOnCurrentPath";
import { useIsMobile } from "common/util/hooks/useIsMobile";
import useRouterPath from "common/util/hooks/usePathWithParamKeys";

import { MhcTopicCategories } from "layout/SideNav";
import { useLocationSwitcherStore } from "common/state/useLocationSwitcherStore";
import { useNavigationStore } from "common/state/useNavigationStore";
import { LocationSwitcherBanner } from "common/components/LocationSwitcher/LocationSwitcherBanner";
import { LocationSwitcherBreadcrumb } from "common/components/LocationSwitcher/LocationSwitcherBreadcrumb";
import LocationSwitcherFallback from "common/components/LocationSwitcher/LocationSwitcherFallback";
import { LocationSwitcherMap } from "common/components/LocationSwitcher/LocationSwitcherMap";
import { LocationSwitcherSearchLocation } from "common/components/LocationSwitcher/LocationSwitcherSearchLocation";
import { LocationSwitcherTips } from "common/components/LocationSwitcher/LocationSwitcherTips";
import LocationSwitcherTopLocations from "common/components/LocationSwitcher/LocationSwitcherTopLocations";
import { StepLabel } from "common/components/LocationSwitcher/StepLabel";
import Row from "common/components/Row";

interface Props {
  location?: MhcLocationFragment;
  locations: MhcLocationFragment[];
  topic?: MhcTopicFragment | null;
  items?: MhcTopicCategories;
  forHomePage?: boolean;
  onClose?: () => void;
  defaultGeography?: NoCountry;
  showStepLabel?: boolean;
}

export const LocationSwitcherContent = ({
  location,
  locations,
  topic: _topic,
  items,
  onClose,
  forHomePage = false,
  defaultGeography,
  showStepLabel = false
}: Props) => {
  const { setIsNavigating, isNavigating } = useNavigationStore((store) => store);
  const isMobile = useIsMobile();
  const router = useRouter();
  const { pathWithParamKeys, params } = useRouterPath();
  const [selectedGeoJson, setSelectedGeoJson] = useState<MhcFeatureCollection | undefined>();
  const [availableGeographies, setAvailableGeographies] = useState<NoCountry[]>([]);
  const [locationsWithData, setLocationWithData] = useState<MhcLocation[]>([]);
  const [topLocations, setTopLocations] = useState<MhcLocationFragment[]>();
  const [loadingGeography, setLoadingGeography] = useState<boolean>(false);
  const [topic, setTopic] = useState<MhcTopicFragment | null | undefined>(_topic);
  const [locationSwitcherGeoJsonByGeography, setLocationSwitcherGeoJsonByGeography] = useState<
    Partial<Record<NoCountry, MhcFeatureCollection>>
  >(
    groupAndTurnLocationsIntoFeatures({
      locations: (locations ?? []) as MhcLocation[],
      selectedLocationId: location?.id
    }).featureMap
  );

  const {
    updateState: updateLocationSwitcherState,
    selectedId,
    shouldShowOnlyLocationsWithData,
    locationSwitcherSelectedGeography
  } = useLocationSwitcherStore((store) => ({
    ...store,
    selectedId: store.selectedId ?? location?.id,
    locationSwitcherSelectedGeography: (store.locationSwitcherSelectedGeography ??
      defaultGeography ??
      location?.geography ??
      "state") as NoCountry
  }));

  useEffect(() => {
    if (isNil(topic)) return;
    updateLocationSwitcherState({ selectedTopic: topic });
  }, [topic, updateLocationSwitcherState]);

  const updateTopic = useCallback((topic?: MhcTopicFragment) => {
    setTopic(topic);
  }, []);

  useEffect(() => {
    let availableGeographies: MhcGeographyEnum[] = [];
    topic?.statIdentifiers?.forEach((si) => {
      if (si.availableGeographies)
        availableGeographies = availableGeographies.concat(si.availableGeographies);
    });
    setAvailableGeographies(
      [...new Set(availableGeographies)].filter((geo) => geo !== "country") as Exclude<
        MhcGeographyEnum,
        "country"
      >[]
    );
    const filteredLocations = allLocationsBasicData.filter((location) =>
      availableGeographies.includes(location?.geography)
    );
    setLocationWithData(filteredLocations as MhcLocation[]);
  }, [locations, topic?.statIdentifiers]);

  const handleGeographyUpdate = useCallback(
    (geo: NoCountry) => {
      updateLocationSwitcherState({ locationSwitcherSelectedGeography: geo });
      if (loadingGeography === true) return;
      setTopLocations([]);
      setLoadingGeography(true);
      const loadNewGeography = async () => {
        if (loadingGeography) return;
        const locations = await getMhcMapDataWithStats({
          geographies: [geo],
          statIds: ["POPULATION_TOTAL_COUNT"]
        });
        const { featureMap: mappedFeatures } = groupAndTurnLocationsIntoFeatures({
          locations: locations as MhcLocation[],
          selectedLocationId: location?.id
        });
        setSelectedGeoJson(mappedFeatures[geo]);
        const copy: Partial<Record<NoCountry, MhcFeatureCollection>> | undefined = {
          ...(locationSwitcherGeoJsonByGeography ?? {})
        };
        const updated = mappedFeatures[geo];
        if (updated) copy[geo] = updated;
        setLocationSwitcherGeoJsonByGeography(copy);
      };
      void loadNewGeography().then(() => setLoadingGeography(false));
    },
    [loadingGeography, location, locationSwitcherGeoJsonByGeography, updateLocationSwitcherState]
  );

  useEffect(() => {
    if (loadingGeography === false && !isNil(defaultGeography) && isNil(selectedGeoJson)) {
      handleGeographyUpdate(defaultGeography);
    }
  }, [defaultGeography, handleGeographyUpdate, loadingGeography, selectedGeoJson]);

  useEffect(() => {
    if (!locationSwitcherGeoJsonByGeography || !locationSwitcherSelectedGeography) return;
    const geoJson = locationSwitcherGeoJsonByGeography[locationSwitcherSelectedGeography];
    if (!geoJson) return;
    if (geoJson) setSelectedGeoJson(geoJson);
    const top6FeaturesByPopulation = [...geoJson?.features]
      ?.sort((featureA, featureB) => {
        const statA = featureA.properties?.stats;
        const populationValueA = statA && statA.length > 0 ? statA[0]?.lastValue : undefined;
        const statB = featureB.properties?.stats;
        const populationValueB = statB && statB.length > 0 ? statB[0]?.lastValue : undefined;
        if (populationValueA && populationValueB)
          return populationValueA > populationValueB ? -1 : 1;
        return 0;
      })
      .slice(0, 5);
    const topLocations: MhcLocationFragment[] =
      locationSwitcherSelectedGeography === "state"
        ? mostPopularLocations.slice(0, 5)
        : top6FeaturesByPopulation.map((feature) => {
            return {
              __typename: "MhcLocation",
              id: feature.properties.id,
              name: feature.properties.name,
              geography: locationSwitcherSelectedGeography
            };
          });
    setTopLocations(topLocations);
  }, [
    locationSwitcherGeoJsonByGeography,
    locationSwitcherSelectedGeography,
    loadingGeography,
    handleGeographyUpdate
  ]);

  const noDataAvailability = useMemo(() => {
    if (topic?.statIdentifiers === undefined || topic.statIdentifiers?.length === 0) return true;
    return false;
  }, [topic?.statIdentifiers]);

  const dataAvailableForGranularity = useMemo(() => {
    let dataAvailable = false;
    topic?.statIdentifiers?.forEach((si) => {
      if (
        locationSwitcherSelectedGeography &&
        si.availableGeographies.includes(locationSwitcherSelectedGeography)
      ) {
        dataAvailable = true;
      }
    });
    return dataAvailable;
  }, [locationSwitcherSelectedGeography, topic?.statIdentifiers]);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const geo = event.target.value as NoCountry;
    handleGeographyUpdate(geo);
  };
  const navigateToLocation = useCallback(
    (location?: MhcLocationFragment) => {
      setIsNavigating(true);
      updateLocationSwitcherState({
        selectedId: location?.id ?? "state",
        shouldShowOnlyLocationsWithData: false,
        locationSwitcherSelectedGeography: (location?.geography ?? "state") as NoCountry
      });
      if (forHomePage === true && !isNil(topic)) {
        router.push(
          topic.href
            ? topic.href.replace("state", location?.id ?? "state")
            : `/topics/${topic.slug}`
        );
        return;
      }
      const newPath = updateLocationOnCurrentPath({
        locationId: location?.id ?? "",
        params,
        pathname: pathWithParamKeys
      });
      if (newPath) {
        void router.push(newPath);
        onClose?.();
      }
    },
    [
      forHomePage,
      onClose,
      params,
      pathWithParamKeys,
      router,
      setIsNavigating,
      topic,
      updateLocationSwitcherState
    ]
  );

  if (isNavigating === true) return <LocationSwitcherFallback />;

  return (
    <Box>
      <Stack gap={4}>
        <Stack gap={1.5} id="location-switcher-header">
          {forHomePage === false && (
            <Row alignItems="center">
              <Breadcrumbs
                separator={
                  <ChevronRightIcon
                    color="inherit"
                    sx={{
                      fontSize: "24px",
                      color: "rgba(0,0,0,0.54)"
                    }}
                  />
                }
              >
                {location?.ancestors?.map((location, index) => (
                  <LocationSwitcherBreadcrumb
                    key={index}
                    location={location as MhcLocationFragment}
                    currentTopicName={topic?.name}
                    navigateToLocation={navigateToLocation}
                  />
                ))}
                <Typography color="light.primary" fontWeight={600} component="p" variant="body1">
                  {location?.name}
                </Typography>
              </Breadcrumbs>
            </Row>
          )}
          {forHomePage === true && <Typography variant="h3">Search Topics & Locations</Typography>}
          <LocationSwitcherBanner
            location={location}
            topic={topic}
            items={items}
            onlyLocationsWithData={shouldShowOnlyLocationsWithData}
            dataAvailableForGranularity={dataAvailableForGranularity}
            availableGeographies={availableGeographies}
            handleGeographyUpdate={handleGeographyUpdate}
            noDataAvailability={noDataAvailability}
            forHomePage={forHomePage}
            setTopic={updateTopic}
            showStepLabel={showStepLabel}
          />
        </Stack>
        <Stack
          direction={{ xs: "column", md: "row" }}
          flex={1}
          justifyContent="space-between"
          flexGrow={1}
          gap={4}
        >
          {showStepLabel && <StepLabel step="2" />}
          <Stack flex={1} direction="row" flexGrow={1} width="100%">
            {selectedGeoJson && (
              <LocationSwitcherMap
                availableGeographies={availableGeographies}
                closeModal={onClose}
                currentTopicName={topic?.name}
                handleGeographyChange={handleOnChange}
                loadingMap={loadingGeography}
                selectedGeography={locationSwitcherSelectedGeography}
                selectedGeoJson={selectedGeoJson}
                selectedId={selectedId}
                showOnlyLocationsWithData={shouldShowOnlyLocationsWithData}
              />
            )}
          </Stack>
          <Stack flex={1} direction="row" flexGrow={1}>
            <Stack height="100%" width="100%">
              <LocationSwitcherSearchLocation
                selectedId={selectedId}
                onlyLocationsWithData={shouldShowOnlyLocationsWithData}
                locationsWithData={locationsWithData}
                noDataAvailability={noDataAvailability}
                dataAvailableForGranularity={dataAvailableForGranularity}
                selectedGeography={locationSwitcherSelectedGeography}
                topLocations={isMobile ? undefined : topLocations}
                topic={topic}
                navigateToLocation={navigateToLocation}
                forHomePage={forHomePage}
              />
              {!isMobile && <LocationSwitcherTips />}
            </Stack>
          </Stack>
        </Stack>
        {isMobile && (
          <Stack flex={1} flexGrow={1} direction="column" sx={{ width: "100%" }} gap={4}>
            <LocationSwitcherTopLocations
              locations={topLocations}
              topic={topic}
              selectedGeography={locationSwitcherSelectedGeography}
              navigateToLocation={navigateToLocation}
              forHomePage={forHomePage}
            />
            <LocationSwitcherTips />
          </Stack>
        )}
      </Stack>
    </Box>
  );
};

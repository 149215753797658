"use client";

import HomeIcon from "@mui/icons-material/Home";
import { Box } from "@mui/material";

import { MhcTopicCategoryEnum } from "graphqlApi/types";

import { transformTopicsToNavItems } from "./util";
import { humanize, titleize } from "common/util/helpers";
import { useIsMobile } from "common/util/hooks/useIsMobile";
import { MhcIconTypeMap } from "common/util/mui";

import { LocationChanger } from "layout/LocationMenu/LocationChanger";
import { SideNavMapWithLoadedLocation } from "layout/SideNav/SideNavMapWithLoadedLocation";
import { addOverviewItems } from "layout/SideNav/SideNavMenu/MenuItems/sortItems";
import { FuzzySearch } from "layout/SiteTopNav/FuzzySearch/FuzzySearch";
import { SideNavProps } from "./SideNav";
import { NavItem, SideNavMenu, SideNavMenuProps } from "./SideNavMenu";

export const SideNavAppRouter: React.FC<SideNavProps> = ({
  allowLocationChange = true,
  location,
  selectedItemId,
  selectedItemName,
  items = {},
  topic: _topic,
  locations,
  searchEnabled = true
}) => {
  const isMobile = useIsMobile();

  const allItems: SideNavMenuProps["items"] = addOverviewItems([
    {
      id: "home",
      title: "Home",
      href: "/home",
      type: "category",
      icon: <HomeIcon />,
      level: 1,
      category: "all_topics"
    },
    ...(Object.entries(items).map(([key, category]) => {
      const icon = category.icon ?? category.id;
      const IconComponent = icon ? MhcIconTypeMap[icon] : null;
      return {
        id: category.id,
        title: category?.label || titleize(humanize(category.id)),
        href: category.href || undefined,
        type: "category",
        icon: IconComponent ? <IconComponent /> : <></>,
        items: transformTopicsToNavItems({
          topics: category.children,
          level: key === "all_topics" ? 2 : 1,
          category: key as MhcTopicCategoryEnum
        }),
        showCategory: key === "all_topics" ? true : false,
        level: 1,
        category: key
      };
    }) as NavItem[])
  ]);

  return (
    <Box
      className="web-only-no-pdf"
      sx={{
        display: "flex",
        flexDirection: "column",
        overflow: "hidden"
      }}
    >
      {!isMobile && location && (
        <SideNavMapWithLoadedLocation
          locations={locations}
          location={location}
          topic={_topic}
          allowLocationChange={allowLocationChange}
        />
      )}
      {isMobile && (
        <Box>
          <Box
            sx={{
              backgroundColor: "component.background",
              px: 4,
              py: 2,
              position: "relative",
              zIndex: 1
            }}
          >
            {searchEnabled && <FuzzySearch uiLocation="Side nav" />}
          </Box>
          {locations && allowLocationChange && (
            <Box sx={{ backgroundColor: "brand.light", px: 4, py: 2 }}>
              <LocationChanger
                location={location}
                topic={_topic}
                uiLocation="SideNav MiniMap"
                locations={locations}
              />
            </Box>
          )}
        </Box>
      )}
      <Box sx={{ flex: 1, overflow: "auto", p: 2, px: 0 }}>
        <SideNavMenu items={allItems} selected={selectedItemId || selectedItemName} />
      </Box>
    </Box>
  );
};

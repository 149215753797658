"use client";
// it needs to be a client component, otherwise sharing functions and updating the client state doesn't work properly

import React from "react";

import { MhcGeographyEnum, MhcLocationFragment, MhcTopicFragment } from "graphqlApi/types";

import { useLocationSwitcherStore } from "common/state/useLocationSwitcherStore";
import { LocationSwitcherContent } from "common/components/LocationSwitcher/LocationSwitcherContent";
import { MhcModal } from "../MhcModal";

export interface LocationSwitcherProps {
  location: MhcLocationFragment;
  locations: MhcLocationFragment[];
  topic?: MhcTopicFragment | null;
  open: boolean;
  _onClose?: () => void;
}

export const LocationSwitcher: React.FC<LocationSwitcherProps> = ({
  location,
  locations,
  topic,
  open,
  _onClose
}) => {
  const { updateState: updateLocationSwitcherState } = useLocationSwitcherStore((store) => store);

  const onClose = (reset?: boolean) => {
    // As requested on the QA reviews, the state of the Location Switcher should
    // be reset every time it's closed.
    updateLocationSwitcherState({ shouldShowOnlyLocationsWithData: false });
    if (reset === true) {
      updateLocationSwitcherState({
        selectedId: location.id,
        locationSwitcherSelectedGeography: location.geography as Exclude<
          MhcGeographyEnum,
          "country"
        >
      });
    }
    _onClose?.();
  };

  return (
    <MhcModal
      open={open}
      handleClose={() => onClose(true)}
      title="Change Location"
      width={{ xs: "100%", md: "85vw", lg: "65vw" }}
      modalPaperStyle={{
        maxWidth: { xs: "none" },
        borderRadius: { xs: 0, md: "4px" },
        position: "relative"
      }}
      contentStyle={{ pt: 1 }}
      headerStyle={{ pb: 1 }}
    >
      <LocationSwitcherContent
        location={location}
        locations={locations}
        topic={topic}
        onClose={onClose}
      />
    </MhcModal>
  );
};
